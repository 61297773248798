@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

:root {
  --green-caerlsa: #18a940;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 20px;
  height: 100%;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 3rem auto;
}

span.newline {
  display: block;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 26px;
  }
}
