.contact--section {
  display: flex;
}

.contact--content {
  font-size: 0.85rem;
}

.contact--content h1 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.contact--content a {
  text-decoration: none;
  color: inherit;
  margin-bottom: 0.5rem;
  cursor: pointer;
  float: left;
  clear: left;
}

.contact--content a:hover,
.contact--content a:visited {
  color: inherit;
}

.contact--icon {
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .contact--content h1 {
    font-size: 3.5rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 1440px) {
  .contact--content h1 {
    font-size: 4rem;
    font-weight: 400;
  }
}
