.inventory--section {
  background-color: var(--green-caerlsa);
  display: flex;
  color: white;
}

.inventory--content {
  display: grid;
  gap: 0.5rem;
}

.inventory--content--left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 2;
  line-height: 1.8rem;
  /* padding: 0 0.5rem; */
}

.inventory--content--left h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

.inventory--content--left h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.inventory--content--right img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .inventory--content {
    grid-template-columns: repeat(2, 1fr);
  }

  .inventory--content--left {
    grid-row: 1;
    line-height: 2rem;
  }
}
