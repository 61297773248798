.work--section {
  display: flex;
}

.work--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.work--content h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

.work--content--elements {
  margin-top: 3rem;
  display: grid;
  gap: 1rem;
}

.work--element {
}

.work--content h2 {
  font-size: 1.65rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.work--content p {
  font-size: 0.8rem;
}

@media screen and (min-width: 768px) {
  .work--content--elements {
    grid-template-columns: repeat(3, 1fr);
  }

  .work--content h2 {
    font-size: 1.6rem;
  }

  .work--content p {
    font-size: 0.75rem;
  }
}
