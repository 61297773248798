.nav-bar--header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: -80px;
  left: 0;
  right: 0;
  z-index: 1000;
  transition-timing-function: ease-in;
  transition: 0.2s;
  background-color: transparent;
  padding: 0 7%;
}

.nav-bar--header a {
  height: 100%;
  color: var(--green-caerlsa);
}

.nav-bar--header a:hover,
.nav-bar--header a:visited {
  color: var(--green-caerlsa);
}

.nav-bar--header a:nth-child(2) {
  height: auto;
}

.nav-bar--header img {
  height: 100%;
  width: auto;
  padding: 0.8rem 0;
}

.active {
  top: 0px;
  background-color: white;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.top {
  background-color: transparent;
}

@media screen and (min-width: 768px) {
  .nav-bar--header {
    height: 150px;
    justify-content: center;
    top: -150px;
  }

  .nav-bar--header img {
    height: 100%;
    width: auto;
    padding: 1rem;
  }

  .nav-bar--header a:nth-child(2) {
    position: absolute;
    display: block;
    right: 5%;
  }

  .active {
    top: 0px;
    background-color: white;
    transition-timing-function: ease-in;
    transition: 0.2s;
  }

  .top {
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .nav-bar--header img {
    padding: 0.7rem;
  }
}
