.clients--section {
  display: flex;
}

.clients--content {
  display: grid;
  gap: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.clients--content--left {
}

.clients--content--left img {
  width: 100%;
  max-height: 256px;
  object-fit: cover;
  object-position: 50% 50%;
}

.clients--content--right p {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 320px) {
  .clients--content--left img {
    max-height: 312px;
  }
}

@media screen and (min-width: 768px) {
  .clients--content {
    grid-template-columns: repeat(2, 1fr);
  }

  .clients--content--left img {
    max-height: 350px;
  }
}

@media screen and (min-width: 1440px) {
  .clients--content--left img {
    max-height: 400px;
  }
}
