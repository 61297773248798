.hero--section {
  height: 100%;
  max-height: 624px;
  background-image: url(../img/hero.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.75);
  /* max-height: 428px; */
}

.hero--content {
  height: 100%;
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero--content h1 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
}

.hero--content p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
  line-height: 1.8rem;
}

@media screen and (min-width: 768px) {
  .hero--section {
    max-height: none;
  }
}
