.images--section {
  background-color: var(--green-caerlsa);
  display: flex;
  /* height: 100%; */
}

.images--content {
  width: 90%;
  max-width: 1400px;
  margin: 3rem auto 10rem;
}

.carousel-item img {
  height: 50vh;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .carousel-item img {
    height: 90vh;
  }
}
