@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
:root {
  --green-caerlsa: #18a940;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 20px;
  height: 100%;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 3rem auto;
}

span.newline {
  display: block;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 26px;
  }
}


.nav-bar--header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: -80px;
  left: 0;
  right: 0;
  z-index: 1000;
  transition-timing-function: ease-in;
  transition: 0.2s;
  background-color: transparent;
  padding: 0 7%;
}

.nav-bar--header a {
  height: 100%;
  color: var(--green-caerlsa);
}

.nav-bar--header a:hover,
.nav-bar--header a:visited {
  color: var(--green-caerlsa);
}

.nav-bar--header a:nth-child(2) {
  height: auto;
}

.nav-bar--header img {
  height: 100%;
  width: auto;
  padding: 0.8rem 0;
}

.active {
  top: 0px;
  background-color: white;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.top {
  background-color: transparent;
}

@media screen and (min-width: 768px) {
  .nav-bar--header {
    height: 150px;
    justify-content: center;
    top: -150px;
  }

  .nav-bar--header img {
    height: 100%;
    width: auto;
    padding: 1rem;
  }

  .nav-bar--header a:nth-child(2) {
    position: absolute;
    display: block;
    right: 5%;
  }

  .active {
    top: 0px;
    background-color: white;
    transition-timing-function: ease-in;
    transition: 0.2s;
  }

  .top {
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .nav-bar--header img {
    padding: 0.7rem;
  }
}

.hero--section {
  height: 100%;
  max-height: 624px;
  background-image: url(../../static/media/hero.5f4005e6.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.75);
  /* max-height: 428px; */
}

.hero--content {
  height: 100%;
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero--content h1 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
}

.hero--content p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
  line-height: 1.8rem;
}

@media screen and (min-width: 768px) {
  .hero--section {
    max-height: none;
  }
}

.clients--section {
  display: flex;
}

.clients--content {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.clients--content--left {
}

.clients--content--left img {
  width: 100%;
  max-height: 256px;
  object-fit: cover;
  object-position: 50% 50%;
}

.clients--content--right p {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 320px) {
  .clients--content--left img {
    max-height: 312px;
  }
}

@media screen and (min-width: 768px) {
  .clients--content {
    grid-template-columns: repeat(2, 1fr);
  }

  .clients--content--left img {
    max-height: 350px;
  }
}

@media screen and (min-width: 1440px) {
  .clients--content--left img {
    max-height: 400px;
  }
}

.work--section {
  display: flex;
}

.work--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.work--content h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

.work--content--elements {
  margin-top: 3rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.work--element {
}

.work--content h2 {
  font-size: 1.65rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.work--content p {
  font-size: 0.8rem;
}

@media screen and (min-width: 768px) {
  .work--content--elements {
    grid-template-columns: repeat(3, 1fr);
  }

  .work--content h2 {
    font-size: 1.6rem;
  }

  .work--content p {
    font-size: 0.75rem;
  }
}

.hero2--section {
  height: 100vh;
  background-image: url(../../static/media/img2.f0d97337.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
}

.hero2--content {
  color: white;
  text-align: center;
  /* max-width: 60%; */
  width: 90%;
  max-width: 950px;
}

.hero2--content h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hero2--content h4 {
  max-width: 70%;
  font-size: 1rem;
  margin: 1rem auto 0;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {
  .hero2--content h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .hero2--content h4 {
    max-width: 70%;
    font-size: 1.1rem;
    margin: 1rem auto 0;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.inventory--section {
  background-color: var(--green-caerlsa);
  display: flex;
  color: white;
}

.inventory--content {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.inventory--content--left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 2;
  line-height: 1.8rem;
  /* padding: 0 0.5rem; */
}

.inventory--content--left h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

.inventory--content--left h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.inventory--content--right img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .inventory--content {
    grid-template-columns: repeat(2, 1fr);
  }

  .inventory--content--left {
    grid-row: 1;
    line-height: 2rem;
  }
}

.images--section {
  background-color: var(--green-caerlsa);
  display: flex;
  /* height: 100%; */
}

.images--content {
  width: 90%;
  max-width: 1400px;
  margin: 3rem auto 10rem;
}

.carousel-item img {
  height: 50vh;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .carousel-item img {
    height: 90vh;
  }
}

.certification--section {
  display: flex;
  background-image: url(../../static/media/cert.d146da8d.png);
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  /* padding: 3rem; */
}

.certification--content {
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  padding: 15vmax 5vmax 5vmax;
}

.certification--content p {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .certification--content p {
    font-size: 1.8rem;
    line-height: 2rem;
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .certification--content p {
    font-size: 2.5rem;
    line-height: 2.8rem;
    width: 80%;
  }
}

.contact--section {
  display: flex;
}

.contact--content {
  font-size: 0.85rem;
}

.contact--content h1 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.contact--content a {
  text-decoration: none;
  color: inherit;
  margin-bottom: 0.5rem;
  cursor: pointer;
  float: left;
  clear: left;
}

.contact--content a:hover,
.contact--content a:visited {
  color: inherit;
}

.contact--icon {
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .contact--content h1 {
    font-size: 3.5rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 1440px) {
  .contact--content h1 {
    font-size: 4rem;
    font-weight: 400;
  }
}

.footer--wrapper {
  background-color: var(--green-caerlsa);
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
}

.footer--wrapper p {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.footer--wrapper a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.footer--wrapper a:hover,
.footer--wrapper a:visited {
  color: inherit;
}

