.certification--section {
  display: flex;
  background-image: url(../img/cert.png);
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  /* padding: 3rem; */
}

.certification--content {
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  padding: 15vmax 5vmax 5vmax;
}

.certification--content p {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .certification--content p {
    font-size: 1.8rem;
    line-height: 2rem;
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .certification--content p {
    font-size: 2.5rem;
    line-height: 2.8rem;
    width: 80%;
  }
}
