.footer--wrapper {
  background-color: var(--green-caerlsa);
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
}

.footer--wrapper p {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.footer--wrapper a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.footer--wrapper a:hover,
.footer--wrapper a:visited {
  color: inherit;
}
