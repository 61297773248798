.hero2--section {
  height: 100vh;
  background-image: url(../img/img2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
}

.hero2--content {
  color: white;
  text-align: center;
  /* max-width: 60%; */
  width: 90%;
  max-width: 950px;
}

.hero2--content h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hero2--content h4 {
  max-width: 70%;
  font-size: 1rem;
  margin: 1rem auto 0;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {
  .hero2--content h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .hero2--content h4 {
    max-width: 70%;
    font-size: 1.1rem;
    margin: 1rem auto 0;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
